@font-face {
  font-family: 'Forza';
  src: url('./Forza-Black.woff2') format('woff2'), url('./Forza-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap
}

@font-face {
  font-family: 'Forza';
  src: url('./Forza-BlackItalic.woff2') format('woff2'), url('./Forza-BlackItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  font-display: swap
}

@font-face {
  font-family: 'Forza';
  src: url('./Forza-Bold.woff2') format('woff2'), url('./Forza-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap
}

@font-face {
  font-family: 'Forza';
  src: url('./Forza-BoldItalic.woff2') format('woff2'), url('./Forza-BoldItalic.woff') format('woff');
  font-weight: 700;
  font-style: italic;
  font-display: swap
}

@font-face {
  font-family: 'Forza';
  src: url('./Forza-Medium.woff2') format('woff2'), url('./Forza-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap
}

@font-face {
  font-family: 'Forza';
  src: url('./Forza-MediumItalic.woff2') format('woff2'), url('./Forza-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap
}

@font-face {
  font-family: 'Forza';
  src: url('./Forza-Book.woff2') format('woff2'), url('./Forza-Book.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap
}

@font-face {
  font-family: 'Forza';
  src: url('./Forza-BookItalic.woff2') format('woff2'), url('./Forza-BookItalic.woff') format('woff');
  font-weight: 400;
  font-style: italic;
  font-display: swap
}

@font-face {
  font-family: 'Forza';
  src: url('./Forza-Light.woff2') format('woff2'), url('./Forza-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap
}

@font-face {
  font-family: 'Forza';
  src: url('./Forza-LightItalic.woff2') format('woff2'), url('./Forza-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap
}

@font-face {
  font-family: 'Forza';
  src: url('./Forza-Thin.woff2') format('woff2'), url('./Forza-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap
}

@font-face {
  font-family: 'Forza';
  src: url('./Forza-ThinItalic.woff2') format('woff2'), url('./Forza-ThinItalic.woff') format('woff');
  font-weight: 100;
  font-style: italic;
  font-display: swap
}