@tailwind base;
@tailwind components;
@tailwind utilities;




/* Home Page Starts here*/

@layer components {

  /* Splide Carousel library custom classes */
  .splide__bar{
    @apply w-12 h-1 rounded-full bg-neutral-400
  }
  .splide__bar.splide__pagination__page.is-active {
    @apply bg-white
  }

  [id^=thumbnail] .splide__slide {
    opacity: 0.4;
  }
  
  [id^=thumbnail] .splide__slide.is-active:not(.splide__slide--clone) {
    opacity: 1;
  }

  .splide__arrows{
    @apply top-1/2  z-10 flex justify-between w-full
  }

  .splide__arrow{
    @apply absolute -translate-y-1/2 flex justify-center items-center
  }

  .splide__arrow.splide__arrow--prev{
    @apply md:-left-6 
  }
  .splide__arrow.splide__arrow--prev svg{
    @apply -scale-100
  }
  .splide__arrow.splide__arrow--next{
    @apply md:-right-6 
  }
  .splide__arrow[aria-label*="first"],
  .splide__arrow[aria-label*="last"]
  {
    @apply invisible;
  }

  [data-content-element-type-alias="gridAnchorTag"]{
    @apply sr-only;
  }

  .btn-primary {
    @apply bg-white text-black rounded-full font-medium font-Tungsten uppercase py-2 px-8;
  }

  .btn-secondary {
    @apply bg-black text-white rounded-full font-medium font-Tungsten uppercase py-2 px-8 tracking-widest;
  }

  .btn-tertiary {
    @apply underline uppercase font-Forza font-bold text-white
  }
  
  .btn-tertiary {
    @apply underline uppercase font-Forza font-bold text-white
  }

  .sales-force-form .form__group {
    @apply relative pt-4 px-0 pb-0 mt-3;
  }

  .sales-force-form .form__field {
    @apply w-full border-0 border-b-black border-b border-solid outline-0 font-Forza text-base font-normal not-italic tracking-[0.7px] text-black px-0 py-2 bg-transparent [transition:border-color_0.2s] focus:outline-none focus:ring-transparent placeholder-transparent;
  }

    .sales-force-form .form__select {
        @apply w-full border-0 border-b-black border-b border-solid font-Forza text-base font-normal not-italic tracking-[0.7px] outline-0 py-2.5 px-0 bg-transparent appearance-none dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200;
    }

  .sales-force-form .form__field::placeholder {
    @apply text-transparent;
  }

  .sales-force-form .form__field:placeholder-shown~.form__label {
    @apply text-base cursor-text top-5 left-1 text-black;
  }

  .sales-force-form label {
    @apply font-Forza text-base font-normal not-italic tracking-[0.7px] text-black;
  }

  .sales-force-form label,
  .sales-force-form .form__field:focus~.form__label {
    @apply absolute top-0 text-left block [transition:0.2s] text-xs text-black;
  }

    .sales-force-form .form__field_error {
        @apply absolute top-0 text-left block [transition:0.2s] text-xs text-black;
    }

    .hp-field{
        display: hidden !important;
    }


}

/* Home Page Ends here*/

