

/**umb_name:Heading 1*/
.rte-text-4xl {
	font-size: 2.25rem /* 36px */;
	line-height: 2.5rem /* 40px */;
}

/**umb_name:Heading 2*/
.rte-text-3xl {
	font-size: 1.875rem /* 30px */;
	line-height: 2.25rem /* 36px */;
}

/**umb_name:Heading 3*/
.rte-text-2xl {
	font-size: 1.5rem /* 24px */;
	line-height: 2rem /* 32px */;
}

/**umb_name:Heading 4*/
.rte-text-xl {
	font-size: 1.25rem;
	/* 20px */line-height: 1.75rem;
	/* 28px */;
}

/**umb_name:Heading 5*/
.rte-text-lg {
	font-size: 1.125rem /* 18px */;
	line-height: 1.75rem /* 28px */;
}

/**umb_name:Small*/
.rte-text-sm {
	font-size: 0.875rem /* 14px */;
	line-height: 1.25rem /* 20px */;
}

/**umb_name:Extra Small*/
.rte-text-xs {
	font-size: 0.75rem /* 12px */;
	line-height: 1rem /* 16px */;
}

/**umb_name:Text XL (3em/48px)*/
.rte-text-5xl {
	font-size: 3rem /* 48px */;
	line-height: 1;
}

/**umb_name:Text XXL (3.75em/60px)*/
.rte-text-6xl {
	font-size: 3.75rem /* 60px */;
	line-height: 1;
}
