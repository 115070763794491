/*Tungsten Font*/
@font-face {
    font-family: 'Tungsten';
    src: url('./Tungsten-Bold.woff2') format('woff2'), url('./Tungsten-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Tungsten';
    src: url('./Tungsten-Semibold.woff2') format('woff2'), url('./Tungsten-Semibold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Tungsten';
    src: url('./Tungsten-Medium.woff2') format('woff2'), url('./Tungsten-Medium.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Tungsten';
    src: url('./Tungsten-Light.woff2') format('woff2'), url('./Tungsten-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}
